import React, { useEffect, useState } from 'react';
// Mui
import { StyledPageRight, StyledPageTextContainer } from "../../components/Custom/mainstyle"
import { ErrorWarningiconSvg, KycVerifiedIconSvg, SendSvg } from '@/shared/svg';

import { hasKey } from '@/shared/methods';

import {
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Textarea,
  Select,
} from '@/components';
import { Grid } from '@mui/material';
import AuthService from '@/services/AuthService';
import { useForm, SubmitHandler } from 'react-hook-form';
import { StyledAlertMessage, StyledAlertMessageSucess } from '../deposit/style';

const Contactcontent = () => {
  const [sourceSubject, setsourceSubject] = useState('');

  const [message, setMessage] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ContactForm>();

  const onSubmit: SubmitHandler<ContactForm> = async data => {
    

    data.agent = process.env.REACT_APP_SITE_TITLE;
    setLoading(true);
    console.log(data, 'data');
    const response = await AuthService.contactForm(data);
    console.log(response, 'response');
    if (response.status) {
      setLoading(false);
      setSuccess(true);
      const msg = () => (<span>Contact form has been submitted successfully </span>);
      setMessage(msg);       
      reset();
      } else {
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data);
    }
  


   
  };
  const handleDocumentTypeValue = (e: any) => {
    setsourceSubject(e.target.value);
  };

  const getUserDetails = async () => {
    const response = await AuthService.getUserData();
    setValue('firstName', response.data.firstName);
    setValue('lastName', response.data.lastName);
    setValue('phone', response.data.phone);
    setValue('email', response.data.email);
  };

  useEffect(() => {
    getUserDetails();
  }, []);
  return (
    <StyledPageRight>
      <StyledPageTextContainer>
        <h5>How can we help ?</h5>
        <form
          className='contactform loginform'
          action='#'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          {success ? (
                    <StyledAlertMessageSucess>
                      <KycVerifiedIconSvg /> {message}
                    </StyledAlertMessageSucess>
                  ) : null}
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ paddingBottom: '20px' }}>
            <Grid item xs={6} className='no_padding'>
              <Label display='block' mb='5px' htmlFor='email'>
                First Name
              </Label>
              <InputGroup>
                <Input
                  className='mobilenumber'
                  type='text'
                  id='firstName'
                  name='firstName'
                  placeholder='First Name'
                  readonly={false}
                  feedbackText={errors?.firstName?.message}
                  state={
                    hasKey(errors, 'firstName') ? 'error' : 'success'
                  }
                  showState={!!hasKey(errors, 'first')}
                  {...register('firstName', {
                    required: 'First Name is required',
                  })}
                />
              </InputGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Last Name
                </Label>
                <InputGroup>
                  <Input
                    className='mobilenumber'
                    type='text'
                    id='last'
                    readonly={false}
                    name='lastName'
                    placeholder='Last Name'
                    feedbackText={errors?.lastName?.message}
                    state={
                      hasKey(errors, 'lastName') ? 'error' : 'success'
                    }
                    showState={!!hasKey(errors, 'lastName')}
                    {...register('lastName', {
                      required: 'Last Name is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ paddingBottom: '20px' }}>
            <Grid item xs={6} className='no_padding'>
              <Label display='block' mb='5px' htmlFor='email'>
                Phone
              </Label>
              <InputGroup>
                <Input
                  className='mobilenumber'
                  type='text'
                  readonly={false}
                  id='phone'
                  name='phone'
                  placeholder='Phone Number'
                  feedbackText={errors?.phone?.message}
                  state={hasKey(errors, 'phone') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'phone')}
                  {...register('phone', {
                    required: 'Phone is required',
                  })}
                />
              </InputGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Email
                </Label>
                <InputGroup>
                  <Input
                    className='mobilenumber'
                    type='text'
                    readonly={false}
                    id='email'
                    name='email'
                    feedbackText={errors?.email?.message}
                    state={hasKey(errors, 'email') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'email')}
                    {...register('email', {
                      required: 'Email is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ paddingBottom: '20px' }}>
            <Grid item xs={12}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Subject
                </Label>
                <InputGroup>
                  <Select
                    id='subject'
                    name='subject'
                    className={`select ${sourceSubject === '' ? 'placeholder' : ''
                      }`}
                    feedbackText={errors?.subject?.message}
                    state={
                      hasKey(errors, 'subject') ? 'error' : 'success'
                    }
                    showState={!!hasKey(errors, 'subject')}
                    {...register('subject', {
                      required: 'Subject is required',
                    })}
                    onChange={e => handleDocumentTypeValue(e)}
                  >
                    <option disabled={true} value='' selected>
                      --Select Subject Type--
                    </option>
                    <option value='withdrawls'>Withdrawals </option>
                    <option value='deposit'>Deposit </option>
                    <option value='kyc'>KYC </option>
                    <option value='accountinfo'>Account info </option>
                    <option value='others'>Others </option>
                    <option value='feedback'>
                      Suggestions / Feedback{' '}
                    </option>
                  </Select>
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <Label display='block' mb='5px' htmlFor='email'>
                Message
              </Label>
              <InputGroup className='textareamessage'>
                <Textarea
                  id='message'
                  name='Message'
                  feedbackText={errors?.message?.message}
                  state={hasKey(errors, 'message') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'message')}
                  {...register('message', {
                    required: 'Message is required',
                    minLength: {
                      value: 6,
                      message: 'Minimum length is 6',
                    },
                  })}
                ></Textarea>
              </InputGroup>
            </Grid>
          </Grid>

          <Button type='submit' color='brand2' className='send-btn'>
            <SendSvg />
            Send Message
          </Button>
        </form>
      </StyledPageTextContainer>
    </StyledPageRight>
  );
};

export default Contactcontent;

