import React from 'react';
import { Link } from 'react-router-dom';
// Mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
// SVG
import { GlobSVG } from '@/shared/svg';
import { backgroundPosition, fontFamily, letterSpacing } from 'styled-system';

// ----------------------------------------------------------------------

const themeColor = process.env.REACT_APP_THEME === 'fairplay' ? '#2F594D' : '#384766';

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  backgroundImage: 'url("https://static-web.fra1.cdn.digitaloceanspaces.com/fairplay/fairplaybannernew.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  borderRadius: '10px',
  height: '12rem',
  border: `1px solid ${themeColor}`,
  [theme.breakpoints.up('xs')]: {
    backgroundImage: 'url("https://static-web.fra1.cdn.digitaloceanspaces.com/fairplay/fairplaybannernew.png")',
   
  },
  '@media (max-width: 500px)': {
    backgroundImage: 'url("https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/fairplaybannermobile.png")',
    backgroundPosition:'bottom'
   
  },
}));

const GradientDiv = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: (
    process.env.REACT_APP_THEME === 'fairplay' ?
      '':
      'linear-gradient(129deg, #f17238 9.87%, #0D1336 38.26%, rgb(23 35 104 / 15%) 74.59%)'
  ),

  // background: (
  //   process.env.REACT_APP_THEME === 'fairplay' ?
  //     'linear-gradient(129deg, #dec74b 9.87%, #0D1336 38.26%, rgb(23 35 104 / 15%) 74.59%)':
  //     'linear-gradient(129deg, #f17238 9.87%, #0D1336 38.26%, rgb(23 35 104 / 15%) 74.59%)'
  // ),

}));

const ContentDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
  padding: 38,
  '@media (max-width: 500px)': {
    padding: '0px 28px 28px 18px',
  }
}));

const TextDiv = styled('div')(({ theme }) => ({
  //position: 'absolute',
  //left: '50%',
  //top: '50%',
  //width: '100%',
  //transform: 'translate(-50%, -50%)',
  textAlign: 'left',
  color: 'white',
  fontFamily: 'Orbitron !important',
  fontSize: '2.5rem',
  fontWeight: 700,
  lineHeight: '3rem',
  letterSpacing: '0.17em',
  textTransform: 'uppercase',
  '@media (max-width: 500px)':  {
    marginTop: '-20px',
    fontSize: '2.2rem',
    lineHeight: '2.2rem',
    
   
  },
}));

const TextSecondLineDiv = styled('div')(({ theme }) => ({
  letterSpacing: '0.25em',
}));

const TextPlayDiv = styled('div')(({ theme }) => ({ 

  fontSize: '0.9rem',
  marginTop: '16px',
  width: 140,
  lineHeight: '1rem',
  padding: '8px 12px',
  backgroundColor: '#00000042',
  color: '#FFFFFF',
  borderRadius: 8,
  border: '1px solid #FFFFFFA6',  
  textAlign:'center', 
  letterSpacing:'0px',
    fontFamily:'Roboto',
  '@media (max-width: 500px)':  { 
    fontSize: '0.9rem',
    marginTop: '10px',
    width: 140,
    lineHeight: '1rem',
    padding: '8px 12px',
    backgroundColor: '#00000042',
    color: '#FFFFFF',
    borderRadius: 8,
    border: '1px solid #FFFFFFA6',  
    textAlign:'center',   
    letterSpacing:'0px',
    fontFamily:'Roboto',

  },

}));

export default function HomeHeader() {
  const canPlay = process.env.REACT_APP_THEME !== 'fairplay';
  const header = (
    <StyledButtonBase disabled={!canPlay}>
      {<GradientDiv>
        <ContentDiv>
          <div style={{ position: 'relative'}}>
          {/* <GlobSVG /> */}
            <TextDiv>
              SPORTS BETTING
              <TextPlayDiv>
              {canPlay ? 'Play now!' : 'Coming soon'}
              </TextPlayDiv>
              {/* <TextSecondLineDiv></TextSecondLineDiv> */}
              {/* <div
                style={{
                  fontSize: '0.9rem',
                  marginTop: '16px',
                  width: 190,
                  lineHeight: '1rem',
                  padding: '8px 12px',
                  backgroundColor: '#00000042',
                  color: '#FFFFFF',
                  borderRadius: 8,
                  border: '1px solid #FFFFFFA6',                  
                }}>
                {canPlay ? 'Play now!' : 'Coming soon'}
              </div> */}
            </TextDiv>
          </div>
        </ContentDiv>
      </GradientDiv>}
    </StyledButtonBase>
  );
  if (canPlay) {
    return (
      <Link to="https://arionplay.com/">
        {header}
      </Link>
    );
  }
  return header;
}